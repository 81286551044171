import React from 'react';

import { Box, Button, Icon, tokens, TypographyAlignments, TypographyVariants } from '@unitoio/mosaic';

import {
  BoldTypography,
  ButtonContainer,
  Feature,
  Features,
  Item,
  Name,
  Price,
  Tagline,
} from '../PlanDetails/PlanDetails';

const features = [
  'All connectors, incl. premium & on-prem connectors',
  'Advanced syncing capabilities',
  'Live and historical data sync',
  'Dedicated Integration consultant',
  'Enterprise procurement & SSO',
  'Developer platform access',
];

function handleOnClick() {
  if (window.HubSpotConversations) {
    window.HubSpotConversations.widget.open();
  } else {
    window.open('https://unito.io/demo/', '_blank');
  }
}

export function EnterprisePlanDetails() {
  return (
    <Item $shouldShowTopBanner={false} $nbPlans={0}>
      <Name variant={TypographyVariants.H2} align={TypographyAlignments.CENTER}>
        Enterprise
      </Name>

      <Tagline variant={TypographyVariants.BODY2} align={TypographyAlignments.CENTER}>
        Our most powerful sync solution
      </Tagline>

      <Price align={TypographyAlignments.CENTER} $isEnterprise>
        Custom
      </Price>

      <BoldTypography variant={TypographyVariants.BODY2} align={TypographyAlignments.CENTER}>
        pricing, built for enterprise scale
      </BoldTypography>

      <Box m={[tokens.spacing.s6, tokens.spacing.s5]}>
        <Name variant={TypographyVariants.BODY1} align={TypographyAlignments.CENTER}>
          Custom usage limits
        </Name>
      </Box>

      <ButtonContainer m={[tokens.spacing.s4, tokens.spacing.s3]}>
        <Button onClick={handleOnClick}>Talk to sales</Button>
      </ButtonContainer>

      <Features>
        {features.map((feature) => (
          <Feature key={feature}>
            <Icon name="check-circle" kind={Icon.KINDS.SOLID} />
            <Box as="span" m={[0.25, 0.25, 0, 0.5]}>
              {feature}
            </Box>
          </Feature>
        ))}
      </Features>
    </Item>
  );
}
