/**
 * @name LinkActions
 * All actions related to links are stored here.
 */
import { getFormValues } from 'redux-form';
import { Map } from 'immutable';
import React from 'react';
import moment from 'moment';

import { notification } from '@unitoio/mosaic';

import { history } from '~/utils/history';
import * as trackingActions from '~/actions/tracking';
import * as websocketActions from '~/actions/websocket';
import * as linkTypes from '~/consts/link';
import * as routes from '~/consts/routes';
import * as trackingTypes from '~/consts/tracking';
import * as websocketTypes from '~/consts/websocket';
import {
  formDataToLinkPayload,
  formDataToLinkPayloadV2,
  getDefaultParamContainerId,
  getFeatureFlagValue,
  getItemFiltersForSide,
  getLinkById,
  getLinkSyncStatus,
  getSelectedOrganizationId,
  getSyncSettingsByLinkId,
  getUserId,
} from '~/reducers';
import * as formUtils from '~/utils/forms';
import * as featureTypes from '~/consts/features';

import { getLastAssociation } from '../containers/FlowBuilder/utils/getLastAssociation';
import { getTestModeTermToUse } from '../containers/FlowBuilder/hooks/useGetTestModeTerm';

export const notifyAboutFeatureEnforcement = (rejectionReasons) => (dispatch) => {
  const formatedRejectionReasons = rejectionReasons.map((reason) => `<li>${reason}</li>`).join('');

  dispatch(
    trackingActions.trackEvent(trackingTypes.USER_DROPDOWN_EVENTS.USER_SAW_BLOCKED_AUTOSYNC, { rejectionReasons }),
  );

  notification.warning({
    message: 'This flow has been set to manual',
    description: (
      <div>
        It goes beyond the limits of your plan. Here's how:
        <br />
        <ul>{formatedRejectionReasons}</ul>
        You can still trigger this flow manually. To remove this limit, you'll need to upgrade your plan.
      </div>
    ),
    duration: 0,
    placement: 'top',
  });
};

export const searchLinks =
  ({ page = 0, pageSize = 0, searchString, siteAdminSearchString, kinds = [], tools = [] } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const selectedOrganizationId = getSelectedOrganizationId(state);

    const response = await dispatch({
      cancelOnActions: [linkTypes.GET_LINKS_REQUEST],
      types: [linkTypes.GET_LINKS_REQUEST, linkTypes.GET_LINKS_SUCCESS, linkTypes.GET_LINKS_FAILURE],
      url: routes.API_PATHS.SEARCH_LINKS({ page, pageSize, searchString, siteAdminSearchString, kinds, tools }),
      meta: { siteAdminSearchString },
    });
    if (!response.hasRequestAborted) {
      const userId = getUserId(state);
      const linkIds = response.links.map((link) => link._id);

      if (linkIds.length) {
        dispatch(
          websocketActions.subscribe({
            currentPage: websocketTypes.WS_SUBSCRIBE_PAGES.SYNC_LIST,
            organizationId: selectedOrganizationId,
            userId,
            linkIds,
          }),
        );
      }
    }
    return response;
  };

export const getLinks =
  ({ page = 0, pageSize = 0, searchString, kinds = [], states = [], tools = [] } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const selectedOrganizationId = getSelectedOrganizationId(state);
    if (!selectedOrganizationId) {
      return null;
    }
    const containerId = getDefaultParamContainerId(state);

    const response = await dispatch({
      cancelOnActions: [linkTypes.GET_LINKS_REQUEST],
      types: [linkTypes.GET_LINKS_REQUEST, linkTypes.GET_LINKS_SUCCESS, linkTypes.GET_LINKS_FAILURE],
      url: routes.API_PATHS.GET_LINKS({
        page,
        pageSize,
        searchString,
        organizationId: selectedOrganizationId,
        containerId,
        kinds,
        tools,
        state: states,
      }),
    });
    if (!response.hasRequestAborted) {
      const userId = getUserId(state);
      const linkIds = response.links.map((link) => link._id);

      if (linkIds.length) {
        dispatch(
          websocketActions.subscribe({
            currentPage: websocketTypes.WS_SUBSCRIBE_PAGES.SYNC_LIST,
            organizationId: selectedOrganizationId,
            userId,
            linkIds,
          }),
        );
      }
    }
    return response;
  };

export const getLinksCountLinks =
  ({ page = 0, pageSize = 1, searchString = '', kinds = [] } = {}) =>
  async (dispatch, getState) => {
    const state = getState();
    const selectedOrganizationId = getSelectedOrganizationId(state);
    if (!selectedOrganizationId) {
      return null;
    }

    const res = await dispatch({
      types: [linkTypes.GET_COUNT_LINKS_REQUEST, linkTypes.GET_COUNT_LINKS_SUCCESS, linkTypes.GET_COUNT_LINKS_FAILURE],
      url: routes.API_PATHS.GET_LINKS({
        page,
        pageSize,
        searchString,
        organizationId: selectedOrganizationId,
        kinds,
      }),
    });

    return res;
  };

export const getLink =
  (linkId, { originalIsAutoSync, isDuplicate = false } = {}, options) =>
  async (dispatch, getState) => {
    const state = getState();
    const { redirect = true, displayError = true } = options || {};
    try {
      const response = await dispatch({
        types: [linkTypes.GET_LINK_REQUEST, linkTypes.GET_LINK_SUCCESS, linkTypes.GET_LINK_FAILURE],
        url: routes.API_PATHS.GET_LINK(linkId),
        meta: { isDuplicate, originalIsAutoSync },
      });

      const userId = getUserId(state);
      const selectedOrganizationId = getSelectedOrganizationId(state);

      dispatch(
        websocketActions.subscribe({
          currentPage: websocketTypes.WS_SUBSCRIBE_PAGES.SYNC_ACTIVITY,
          organizationId: selectedOrganizationId,
          userId,
          linkIds: [linkId],
        }),
      );

      if (displayError) {
        const { containerErrors } = response;
        ['A', 'B'].forEach((side) => {
          if (containerErrors[side]) {
            notification.error({
              message: 'Oops, something went wrong :(',
              description: containerErrors[side].message,
              placement: 'top',
            });
          }
        });
      }

      return response;
    } catch (err) {
      if (redirect) {
        history.push({ pathname: routes.ABSOLUTE_PATHS.DASHBOARD });
      }
      throw err;
    }
  };

export const saveLinkV2 = (formData) => async (dispatch, getState) => {
  const linkData = formDataToLinkPayloadV2(getState(), formData);
  return dispatch({
    types: [linkTypes.SAVE_LINK_V2_REQUEST, linkTypes.SAVE_LINK_V2_SUCCESS, linkTypes.SAVE_LINK_V2_FAILURE],
    url: routes.API_PATHS.SAVE_LINK_V2(formData._id),
    method: routes.METHODS.PUT,
    payload: linkData,
    displayError: false,
  });
};

/**
 * This action edits a link
 * @param {string} linkId - The id of the link
 * @params {object} formData - The data coming from the sync form
 * @returns {object} the action with the payload
 */

export const saveLink =
  (formData, isFlowBuilder = true, displayError = true) =>
  async (dispatch, getState) => {
    const state = getState();

    const isModernRulesPageEnabled = getFeatureFlagValue(state, featureTypes.FEATURES.MODERN_RULES_PAGE);
    const modernRules = formUtils.stripMongooseIds(formData.rules);
    if (isModernRulesPageEnabled) {
      modernRules.A.filters.isActive = true;
      modernRules.B.filters.isActive = true;
      modernRules.A.actionsActive = true;
      modernRules.B.actionsActive = true;
    }

    const syncSettings = getSyncSettingsByLinkId(state, formData._id);

    const itemFieldAssociationsA = formUtils.getItemFieldAssociations(
      formData.A.itemFieldAssociations,
      isFlowBuilder
        ? formUtils.getItemFiltersForSideForFlowBuilder(formData.A.filters)
        : getItemFiltersForSide(state, 'A'),
    );
    const itemFieldAssociationsB = formUtils.getItemFieldAssociations(
      formData.B.itemFieldAssociations,
      isFlowBuilder
        ? formUtils.getItemFiltersForSideForFlowBuilder(formData.B.filters)
        : getItemFiltersForSide(state, 'B'),
    );

    const fieldAssociations = formData.associations;

    const [lastAssociation] = getLastAssociation(fieldAssociations);

    if (lastAssociation?.A?.field === null || lastAssociation?.B?.field === null) {
      fieldAssociations.pop();
    }

    const linkPayload = {
      ...formData,
      rules: modernRules,
      associations: fieldAssociations,
      A: {
        ...formData.A,
        itemFieldAssociations: itemFieldAssociationsA,
      },
      B: {
        ...formData.B,
        itemFieldAssociations: itemFieldAssociationsB,
      },
    };

    // TODO we may want to remove this completely and let only CS be able to provide manualOptions
    // link to documented manual options so far: https://docs.google.com/document/d/11gpbd9wUY1O8uBlIhMYU1iUzlqc00bshnUMXK-ZRzj0/edit#
    try {
      linkPayload.manualOptions = JSON.parse(formData.manualOptions || '{}');
    } catch (err) {
      // If manual options are invalid, keep same value as before
      linkPayload.manualOptions = syncSettings.get('manualOptions', Map()).toJS();
    }

    // TODO centralize logic above in method below to have all potential FlowBuilder related logic
    // in one spot
    const payload = formUtils.getEditSyncPayload({ ...linkPayload }, syncSettings, isFlowBuilder);

    const response = await dispatch({
      types: [linkTypes.SAVE_LINK_REQUEST, linkTypes.SAVE_LINK_SUCCESS, linkTypes.SAVE_LINK_FAILURE],
      url: routes.API_PATHS.SAVE_LINK(formData._id),
      method: routes.METHODS.PUT,
      payload,
      displayError,
    });

    const message = formData.lazyResync
      ? 'Settings will be applied to any items created or modified from this point forward.'
      : 'Settings will be applied to all newly created, modified, and historical items.';

    notification.success({
      description: message,
      placement: 'topRight',
      message: 'Flow settings saved',
      duration: 7,
    });

    const { rejectionReasons } = response;
    if (rejectionReasons.length > 0) {
      dispatch(notifyAboutFeatureEnforcement(rejectionReasons));
    }
    return response;
  };

/**
 * This action sets the state of a link to auto
 * TODO: could be merged with patchLink
 * @param {string} linkId - The id of the link
 */
export const setAutoSyncLink = (linkId) => async (dispatch) => {
  const { rejectionReasons = [] } = await dispatch({
    types: [
      linkTypes.SET_AUTO_SYNC_LINK_REQUEST,
      linkTypes.SET_AUTO_SYNC_LINK_SUCCESS,
      linkTypes.SET_AUTO_SYNC_LINK_FAILURE,
    ],
    url: routes.API_PATHS.SAVE_LINK(linkId),
    payload: { isAutoSync: true },
    method: routes.METHODS.PATCH,
  });

  if (rejectionReasons.length) {
    dispatch(notifyAboutFeatureEnforcement(rejectionReasons));
    return;
  }

  notification.info({
    message: 'Auto sync turned on',
    description: 'Your changes will now automatically be synced at the speed indicated on your subscribed plan',
    placement: 'topRight',
  });
};

/**
 * This action patches a link with a set property
 * @param {string} linkId - The id of the link
 * * @param {object} payload - The data to patch on the link
 */
export const patchLink = (linkId, payload) => async (dispatch) => {
  await dispatch({
    types: [linkTypes.PATCH_LINK_REQUEST, linkTypes.PATCH_LINK_SUCCESS, linkTypes.PATCH_LINK_FAILURE],
    url: routes.API_PATHS.SAVE_LINK(linkId),
    payload,
    method: routes.METHODS.PATCH,
  });
};

/**
 * This action turns the test mode off
 * @param {string} linkId - The id of the link
 */
export const turnOffTestMode = (linkId) => async (dispatch, getState) => {
  const state = getState();
  const link = getLinkById(state, linkId);
  const testModeTerm = getTestModeTermToUse(link);
  const earliestCreatedAt = link.getIn(['syncSettings', 'earliestCreatedAt']);
  const formattedLinkEarliestCreatedAt = moment(earliestCreatedAt).format('MMMM Do, YYYY');

  notification.info({
    message: `'Only ${testModeTerm} items' is removed`,
    description: `Any items matching the flow's filters will now sync, including those created before ${formattedLinkEarliestCreatedAt}`,
    placement: 'topRight',
  });

  return dispatch({
    types: [
      linkTypes.TURN_OFF_TEST_MODE_REQUEST,
      linkTypes.TURN_OFF_TEST_MODE_SUCCESS,
      linkTypes.TURN_OFF_TEST_MODE_FAILURE,
    ],
    url: routes.API_PATHS.SAVE_LINK(linkId),
    payload: { syncSettings: { earliestCreatedAt: null } },
    method: routes.METHODS.PATCH,
  });
};

/**
 * This action edits the link name
 * @param {string} linkId - The id of the link
 * @param {object} fields - object representing the fields to be updated
 */
export const editLinkFields = (linkId, fields) => async (dispatch) =>
  dispatch({
    types: [linkTypes.EDIT_LINK_FIELDS_REQUEST, linkTypes.EDIT_LINK_FIELDS_SUCCESS, linkTypes.EDIT_LINK_FIELDS_FAILURE],
    url: routes.API_PATHS.SAVE_LINK(linkId),
    payload: fields,
    method: routes.METHODS.PATCH,
  });

/**
 * This action sets the state of a link to manual
 * @param {string} id - The id of the link
 */
export const setManualSyncLink = (linkId) => (dispatch) => {
  notification.info({
    message: 'Auto sync turned off',
    description:
      'New changes will no longer be automatically synced. You can manually force them to sync by clicking "Sync now"',
    placement: 'topRight',
  });

  return dispatch({
    types: [
      linkTypes.SET_MANUAL_SYNC_LINK_REQUEST,
      linkTypes.SET_MANUAL_SYNC_LINK_SUCCESS,
      linkTypes.SET_MANUAL_SYNC_LINK_FAILURE,
    ],
    url: routes.API_PATHS.SAVE_LINK(linkId),
    payload: { isAutoSync: false },
    method: routes.METHODS.PATCH,
  });
};

/**
 * This action starts the sync of a given link
 * @param {string} id - The id of the link
 * @param {bool} resync - Wether to force a resync or not
 * @param {{ id: string, kind: string}[]} forcedFields - The fields to limit
 * @param {string} forcedSide - Wether to force a resync or not
 */
export const syncLink =
  (linkId, resync = false, forcedFields, forcedSide) =>
  async (dispatch) => {
    dispatch(dispatchSyncActivity(linkId, { activity: linkTypes.LINK_ACTIVITY_STATUS.TRIGGERED }));

    return dispatch({
      types: [linkTypes.SYNC_LINK_REQUEST, linkTypes.SYNC_LINK_SUCCESS, linkTypes.SYNC_LINK_FAILURE],
      method: routes.METHODS.PUT,
      url: routes.API_PATHS.SYNC_LINK(linkId),
      payload: { resync, forcedFields, forcedSide },
      meta: { linkId, displayErrorOptions: { excludeHelpLink: true, excludeGuideLink: true } },
    });
  };

const dispatchSyncActivity =
  (linkId, { activity = linkTypes.LINK_ACTIVITY_STATUS.TRIGGERED, showNotification = true }) =>
  async (dispatch, getState) => {
    const organizationId = getSelectedOrganizationId(getState());

    const payload = {
      [linkId]: {
        linkId,
        organizationId,
        activity,
        trigger: 'manual',
      },
    };

    dispatch(
      websocketActions.updateTopicByPage(
        websocketTypes.WS_SUBSCRIBE_PAGES.SYNC_ACTIVITY,
        websocketTypes.TOPICS.SYNCS_EVENTS,
        payload,
      ),
    );
    dispatch(
      websocketActions.updateTopicByPage(
        websocketTypes.WS_SUBSCRIBE_PAGES.SYNC_LIST,
        websocketTypes.TOPICS.SYNCS_EVENTS,
        payload,
      ),
    );

    if (showNotification) {
      notification.success({
        message: 'Syncing...',
        description: 'Hang tight while your work is syncing. This may take a couple of minutes',
        placement: 'topRight',
      });
    }
  };

/**
 * This action deletes a link between two containers
 * @param {string} linkId - The id of the link to delete
 */
export const deleteLink = (linkId) => (dispatch) =>
  dispatch({
    types: [linkTypes.DELETE_LINK_REQUEST, linkTypes.DELETE_LINK_SUCCESS, linkTypes.DELETE_LINK_FAILURE],
    method: routes.METHODS.DELETE,
    url: routes.API_PATHS.DELETE_LINK(linkId),
    meta: {
      linkId,
    },
  });

export const automapUsers = (linkId) => ({
  types: [linkTypes.AUTOMAP_USERS_REQUEST, linkTypes.AUTOMAP_USERS_SUCCESS, linkTypes.AUTOMAP_USERS_FAILURE],
  url: routes.API_PATHS.AUTOMAP_USERS(linkId),
  method: routes.METHODS.POST,
});

export const callConnectorFn = (linkId, side, functionName, fieldContext, includeFilteredOutItems, arg) => ({
  types: [linkTypes.DIAGNOSE_LINK_REQUEST, linkTypes.DIAGNOSE_LINK_SUCCESS, linkTypes.DIAGNOSE_LINK_FAILURE],
  url: routes.API_PATHS.DIAGNOSE_LINK(linkId),
  payload: {
    side,
    functionName,
    includeFilteredOutItems,
    fieldContext,
    arg,
  },
  method: routes.METHODS.POST,
});

export const resetDiagnostic = () => ({
  type: linkTypes.RESET_DIAGNOSTIC,
});

// When this function is called directly, you can pass the arguments directly (as used in the workflow designer)
// otherwise you can use a form named syncForm (as used in the sync list)
export const duplicateSync =
  (linkId, formDataParam = null, displayError = true) =>
  async (dispatch, getState) => {
    const state = getState();
    const formData = formDataParam || getFormValues('syncForm')(state);

    const response = await dispatch({
      displayError,
      types: [linkTypes.DUPLICATE_SYNC_REQUEST, linkTypes.DUPLICATE_SYNC_SUCCESS, linkTypes.DUPLICATE_SYNC_FAILURE],
      method: routes.METHODS.POST,
      payload: {
        linkId,
        providerIdentityIdA: formData.A.providerIdentityId,
        providerIdentityIdB: formData.B.providerIdentityId,
        containerTypeA: formData.A.containerType,
        containerTypeB: formData.B.containerType,
        containerIdA: formData.A.containerId,
        containerIdB: formData.B.containerId,
        itemTypeA: formData.A.itemType,
        itemTypeB: formData.B.itemType,
        parentContainerIdA: formData.A.parentContainerId,
        parentContainerIdB: formData.B.parentContainerId,
        nodesA: formData.A.nodes,
        nodesB: formData.B.nodes,
      },
      url: routes.API_PATHS.DUPLICATE_SYNC(linkId),
      meta: { existingContainerA: formData.A.existingContainer, existingContainerB: formData.B.existingContainer },
    });

    return response;
  };

export const setAutoSyncMultisyncLinks = (linkIds) => (dispatch) =>
  Promise.all(
    linkIds.map((linkId) =>
      dispatch({
        types: [
          linkTypes.SET_AUTO_SYNC_LINK_REQUEST,
          linkTypes.SET_AUTO_SYNC_LINK_SUCCESS,
          linkTypes.SET_AUTO_SYNC_LINK_FAILURE,
        ],
        url: routes.API_PATHS.SAVE_LINK(linkId),
        payload: { isAutoSync: true },
        method: routes.METHODS.PATCH,
      }),
    ),
  );

export const getOrganizationTaskSyncsTaskCount = (organizationId, linkIds = []) => ({
  types: [
    linkTypes.GET_TASK_SYNC_TASK_COUNT_REQUEST,
    linkTypes.GET_TASK_SYNC_TASK_COUNT_SUCCESS,
    linkTypes.GET_TASK_SYNC_TASK_COUNT_FAILURE,
  ],
  method: routes.METHODS.POST,
  payload: {
    organizationId,
    kind: linkTypes.KIND.TASK_SYNC,
    linkIds,
  },
  meta: { organizationId },
  url: routes.API_PATHS.GET_TASK_SYNC_TASK_COUNT,
});

export const createFlow = (flowData) => async (dispatch, getState) => {
  const state = getState();
  const payload = formDataToLinkPayload(state, flowData);

  const response = await dispatch({
    types: [linkTypes.ADD_LINK_REQUEST, linkTypes.ADD_LINK_SUCCESS, linkTypes.ADD_LINK_FAILURE],
    method: routes.METHODS.POST,
    url: routes.API_PATHS.LINKS,
    displayError: false,
    payload,
  });

  const { link, rejectionReasons } = response;
  if (rejectionReasons.length > 0) {
    dispatch(notifyAboutFeatureEnforcement(rejectionReasons));
  }

  return link;
};

export const syncLinkItem =
  ({ itemId, linkId, containerId }) =>
  async (dispatch, getState) => {
    const currentActivity =
      getLinkSyncStatus(getState(), linkId)?.get('activity') ?? linkTypes.LINK_ACTIVITY_STATUS.HEALTHY;
    dispatch(
      dispatchSyncActivity(linkId, { activity: linkTypes.LINK_ACTIVITY_STATUS.TRIGGERED, showNotification: false }),
    );

    const { hasTriggeredSync } = await dispatch({
      types: [linkTypes.SYNC_LINK_ITEM_REQUEST, linkTypes.SYNC_LINK_ITEM_SUCCESS, linkTypes.SYNC_LINK_ITEM_FAILURE],
      url: routes.API_PATHS.SYNC_LINK_ITEM(itemId),
      method: routes.METHODS.PUT,
      displayError: false,
      payload: { linkId, containerId },
    });

    if (hasTriggeredSync) {
      notification.success({
        message: 'Syncing...',
        description: 'Hang tight while your work is syncing. This may take a couple of minutes',
        placement: 'topRight',
        duration: 2,
      });
    } else {
      dispatch(dispatchSyncActivity(linkId, { activity: currentActivity, showNotification: false }));
      notification.error({
        message: 'Sync not triggered',
        description: 'This flow has synced not too long ago. Please wait a few seconds before triggering another sync',
        placement: 'topRight',
        duration: 2,
      });
    }
  };

export function validateLinkCanBeAutopopulated(linkId) {
  return {
    types: [
      linkTypes.VALIDATE_LINK_CAN_BE_AUTOPOPULATED_REQUEST,
      linkTypes.VALIDATE_LINK_CAN_BE_AUTOPOPULATED_SUCCESS,
      linkTypes.VALIDATE_LINK_CAN_BE_AUTOPOPULATED_FAILURE,
    ],
    method: routes.METHODS.GET,
    url: routes.API_PATHS.VALIDATE_LINK_CAN_BE_AUTOPOPULATED(linkId),
    displayError: false,
  };
}

export function autopopulateFields(linkId) {
  return {
    types: [
      linkTypes.AUTOPOPULATE_FIELDS_REQUEST,
      linkTypes.AUTOPOPULATE_FIELDS_SUCCESS,
      linkTypes.AUTOPOPULATE_FIELDS_FAILURE,
    ],
    method: routes.METHODS.POST,
    url: routes.API_PATHS.AUTOPOPULATE_FIELDS(linkId),
    displayError: false,
  };
}
