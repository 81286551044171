export const GET_PLANS_REQUEST = 'GET_PLANS_REQUEST';
export const GET_PLANS_SUCCESS = 'GET_PLANS_SUCCESS';
export const GET_PLANS_FAILURE = 'GET_PLANS_FAILURE';
export const GET_ORGANIZATION_PLAN_PROFILE_REQUEST = 'GET_ORGANIZATION_PLAN_PROFILE_REQUEST';
export const GET_ORGANIZATION_PLAN_PROFILE_SUCCESS = 'GET_ORGANIZATION_PLAN_PROFILE_SUCCESS';
export const GET_ORGANIZATION_PLAN_PROFILE_FAILURE = 'GET_ORGANIZATION_PLAN_PROFILE_FAILURE';
export const GET_PLAN_REJECTION_REASONS_REQUEST = 'GET_PLAN_REJECTION_REASONS_REQUEST';
export const GET_PLAN_REJECTION_REASONS_SUCCESS = 'GET_PLAN_REJECTION_REASONS_SUCCESS';
export const GET_PLAN_REJECTION_REASONS_FAILURE = 'GET_PLAN_REJECTION_REASONS_FAILURE';
export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';
export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCEL_SUBSCRIPTION_REQUEST';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';
export const PAUSE_SUBSCRIPTION_REQUEST = 'PAUSE_SUBSCRIPTION_REQUEST';
export const PAUSE_SUBSCRIPTION_SUCCESS = 'PAUSE_SUBSCRIPTION_SUCCESS';
export const PAUSE_SUBSCRIPTION_FAILURE = 'PAUSE_SUBSCRIPTION_FAILURE';
export const UNPAUSE_SUBSCRIPTION_REQUEST = 'UNPAUSE_SUBSCRIPTION_REQUEST';
export const UNPAUSE_SUBSCRIPTION_SUCCESS = 'UNPAUSE_SUBSCRIPTION_SUCCESS';
export const UNPAUSE_SUBSCRIPTION_FAILURE = 'UNPAUSE_SUBSCRIPTION_FAILURE';
export const GET_BILLING_ORGANIZATION_REQUEST = 'GET_BILLING_ORGANIZATION_REQUEST';
export const GET_BILLING_ORGANIZATION_SUCCESS = 'GET_BILLING_ORGANIZATION_SUCCESS';
export const GET_BILLING_ORGANIZATION_FAILURE = 'GET_BILLING_ORGANIZATION_FAILURE';
export const GET_ORGANIZATION_INVOICES_REQUEST = 'GET_ORGANIZATION_INVOICES_REQUEST';
export const GET_ORGANIZATION_INVOICES_SUCCESS = 'GET_ORGANIZATION_INVOICES_SUCCESS';
export const GET_ORGANIZATION_INVOICES_FAILURE = 'GET_ORGANIZATION_INVOICES_FAILURE';
export const UPDATE_BILLING_ORGANIZATION_REQUEST = 'UPDATE_BILLING_ORGANIZATION_REQUEST';
export const UPDATE_BILLING_ORGANIZATION_SUCCESS = 'UPDATE_BILLING_ORGANIZATION_SUCCESS';
export const UPDATE_BILLING_ORGANIZATION_FAILURE = 'UPDATE_BILLING_ORGANIZATION_FAILURE';
export const COUPON_FORM_NAME = 'couponForm';
export const PLAN_INTERVALS = {
  YEAR: 'year',
  MONTH: 'month',
};
export const PLAN_TYPES = {
  PERSONAL: 'personal',
  PRO: 'pro',
  TEAM: 'team',
  COMPANY: 'company',
  BUSINESS: 'business',
  PAUSE: 'pause',
  TASK_SYNC: 'mirror',
  BASIC: 'basic',
  SPREADSHEET: 'spreadsheet',
  ENTERPRISE: 'enterprise',
};
